#velo {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    overflow: auto;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &.iphone {
        align-items: flex-end;
        .popup {
            padding-bottom: 120px;
        }
    }
}

.popup {
    width: 100%;
    height: auto;
    max-width: 44rem;
    max-height: 100vh;
    overflow-x: auto;
    z-index: 3000;
    background-color: white;
    border-radius: var(--border-radius);
    padding: 1rem;
    box-shadow: 0px 0px 35px -4px rgba(0, 0, 0, 0.75);
    transform: translateY(50%);
    opacity: 0;
    transition: transform 200ms linear, opacity 200ms linear, width 200ms linear, height 200ms linear;

    &.popupin {
        opacity: 1;
        transform: translateY(0);
    }

    iframe {
        min-height: 80vh;
        height: 100%;
        width: 100%;
    }

    span.material-icons.close {
        cursor: pointer;
        font-size: 1.5rem;
        width: 40px;
        height: 40px;
        text-align: right;
        padding: 0 0 0.5rem 0.5rem;
        margin: 0;
        color: black;
        line-height: 0;
    }

    p {
        text-align: center;
        margin-bottom: 1.5rem;
        color: black;
    }

    .pregunta {
        font-size: 1rem;
        letter-spacing: 1px;
        font-weight: 400;
        font-optical-sizing: 11;
        font-kerning: unset;
        padding: 0.75rem 1rem;
        border-radius: var(--border-radius);
        background: var(--color-brand);
        margin-bottom: 1.25rem;

        p {
            color: var(--color-brand-text);
            margin-bottom: 0;
            width: 100%;
            font-size: 1.25rem;
        }
    }

    .respuesta {
        .col-12 {
            padding: 0;

            textarea {
                width: 100%;
                height: 10rem;
                resize: none;
            }
        }
    }
}


.popup .material-icons {
    border-radius: 100%;
    margin: 0rem auto 1.5rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-size: 6rem;

    &.ok {
        color: #00c853;
        background: white;
    }

    &.error {
        color: #B00020;
    }
}

.polls-radio-toolbar {

    input[type="radio"],
    input[type="checkbox"] {
        opacity: 0;
        position: fixed;
        width: 0;

        &:checked+label {
            border: 5px solid var(--color-brand);
            color: var(--color-brand);
            position: relative;
        }
    }

    label {
        display: inline-block;
        background: #e6e6e6;
        color: #000;
        padding: 0.75rem 1rem;
        font-size: 1rem;
        border-radius: var(--border-radius);
        border: #e6e6e6 5px solid;
        width: 100%;
        min-width: 16rem;
        cursor: pointer;
        transition: all 200ms linear;

        &:hover {
            position: relative;

            &::after {
                background-color: var(--color-brand);
                position: absolute;
                content: '';
                opacity: 0.1;
                left: -5px;
                top: -5px;
                width: calc(100% + 10px);
                height: calc(100% + 10px);
            }
        }
    }
}