.block-fullscreen {
    position: absolute;
    width: 25%;
    height: 70px;
    top: 0;
    left: 0;
}
@media (min-width:768px) {
    .block-fullscreen {
        width: 13%;
    }
}

@media (min-width:1024px){
    .block-fullscreen {
        width: 10%;
    }
}

.block-fullscreen-bottom-right {
    position: absolute;
    width: 25%;
    height: 70px;
    bottom: 0;
    right: 0;
}
@media (min-width:768px) {
    .block-fullscreen-bottom-right {
        width: 13%;
    }
}

@media (min-width:1024px){
    .block-fullscreen-bottom-right {
        width: 10%;
    }
}

.pb-sm--3{
    padding-bottom: 1.5rem;

}
@media(min-width:480px){
    .pb-sm--3{
        padding-bottom: 0;
    }

}
